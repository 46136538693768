<template>
  <div id="app">
    <TopUi :changePage="changePage" ></TopUi>
    <MainForm v-if="page === 'order'" :changUrl="changUrl"></MainForm>
    <CustomOrder v-if="page === 'customOrder'" :changUrl="changUrl"></CustomOrder>
    <addPage v-else-if="page === 'usermsg'" :changUrl="changUrl"></addPage>
    <addProduct v-else-if="page === 'addProduct'" :changUrl="changUrl"></addProduct>
    <AddPrice v-else-if="page === 'changPrice'" :changUrl="changUrl"></AddPrice>
    <FindOrder v-else-if="page === 'FindOrder'" :changUrl="changUrl"></FindOrder>
    <DeFooter></DeFooter>
    <div>
      <a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2022002400号-1</a>
    </div>
  </div>
</template>

<script>
import TopUi from "@/components/TopUi";
import MainForm from "@/components/MainForm";
import DeFooter from "@/components/DeFooter";
import addPage from "@/components/addPage";
import addProduct from "@/components/addProduct";
import AddPrice from "@/components/AddPrice";
import CustomOrder from "@/components/CustomOrder";
import FindOrder from "@/components/FindOrder.vue";

export default {
  name: 'App',
  components: {
    TopUi,
    MainForm,
    DeFooter,
    addPage,
    addProduct,
    AddPrice,
    CustomOrder,
    FindOrder,
  },
  data(){
    return {
      page:"index",
      // changUrl:"http://localhost:8080",
      changUrl:"http://ww.lxflll.xyz:80",
    }

  },
  methods:{
    changePage(newvalue){
      this.page = newvalue;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  margin: auto;
}
</style>
