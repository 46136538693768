<template>
  <div>
    <button @click="changePage('index')">首页</button>
<!--    查询订单  或者新建订单-->
    <button @click="changePage('order')">订单</button>
    <!--    查询订单  或者新建订单-->
    <button @click="changePage('customOrder')">自定义订单</button>
<!--    修改单价  并且每家有不同的单价-->
    <button @click="changePage('changPrice')">修改单价</button>
<!--    添加版型-->
    <button @click="changePage('addProduct')">添加新版型</button>
    <!--    添加和删除客户-->
    <button @click="changePage('usermsg')">客户信息</button>

    <button @click="changePage('FindOrder')">订单信息</button>
  </div>
</template>

<script>
export default {
  name: "TopUi",
  props:["changePage"],
}
</script>

<style scoped>

</style>